import { Component, OnInit, OnDestroy } from '@angular/core';
import { InformationCard } from '../../../../shared/components/info-card/info-card';
import { ActivatedRoute } from '@angular/router';
import { AboutUs } from '../../components/about-us/about-us.interface';
import { ERROR_CATEGORY_CODE } from 'src/app/shared/components/error-handle/error-handle-const';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  ERROR_CATEGORY_CODE = ERROR_CATEGORY_CODE;
  hasErrorOccurred:boolean = false;
  informationCards: InformationCard[] | null = [];
  aboutUs: AboutUs[] | null = [];

  /*Handle Unsubcriptions*/
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this._route.data.pipe(
      takeUntil(this._unsubscribeAll),
    ).subscribe({
      next: (data) =>{
        if(data['contact'].infoCardCollection && data['contact'].aboutUsCollection){ 
          this.hasErrorOccurred = false;
          this.informationCards = data['contact'].infoCardCollection.items;
          this.aboutUs = data['contact'].aboutUsCollection.items;
        }else {
          this.hasErrorOccurred = true;
          this.informationCards = data['contact'].data;
          this.aboutUs = data['contact'].data;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.unsubscribe();
  }
}

