import { AuthenticationService } from 'src/app/features/authentication/services/authentication.service';
import { Component, ViewChild, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FooterResponse } from 'src/app/shared/components/footer/footer-interface';

import { MENU } from '../menu';
import { ProfileCard } from 'src/app/features/dashboard/components/profile-card/profile-card';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { UserProfileData } from 'src/app/features/dashboard/graphql/profile-info.query';
import { environment } from 'src/environments/environment';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConfirmationBottomSheetComponent } from '../confirmation-bottom-sheet.component';
import { UserPortalDialogService } from 'src/app/shared/services/dialog/user-portal-dialog.service';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent implements OnInit, OnDestroy {

  private router = inject(Router);
  private _route = inject(ActivatedRoute);
  private authenticationService = inject(AuthenticationService);
  private dashboardService = inject(DashboardService);
  private breakPointerObserver = inject(BreakpointObserver);
  private bottomSheet = inject(MatBottomSheet);
  private _userPortalDialogService = inject(UserPortalDialogService);

  @Input() menuItems: MENU[] = [];

  @ViewChild('drawer', { static: true }) drawer!: MatDrawer;

  accounts: string[] = [
    'CN - Tracker-IL DOT',
    'Prime Contractor - Freeway Rehabilitation',
    'American Concrete Pumping',
    'David Shoring',
    'e.g., 113272 & 113270 - BELL/CLARK JV - BRIDGESTONE ARENA & CENTRAL POLICE PRECINCT',
  ];

  open: boolean = false;
  selectedProfile = '';
  profileFortifyUrl: string = environment.onboardingClaims.lastProfileChange;

  userProfile: ProfileCard = {
    name: '',
    company: '',
    email: '',
    comment: '',
    photo: '',
  };

  private unsubscribe = new Subject<boolean>();
  footer: FooterResponse[] | null = null;
  profileCard: UserProfileData | null = null;
  hasErrorOccurred: boolean = false;
  hasErrorOccurredForProfile: boolean = false;

  notMobileAllowed: string[] = ['Authentication Controls','Delete Users','Feature Management']
  isMobileScreen: boolean = false;
  bottomSheetRef!: MatBottomSheetRef<MobileLayoutComponent>;
  matDialogRef!: MatDialogRef<MobileLayoutComponent>;

  constructor(

  ) { }

  ngOnInit(): void {
    this.getResolverData();
    this.isMobile();
    this.getUserInfo();
  }

  getResolverData(): void {
    this._route.data.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data) => {
        if (data['footer'].footerCollection) {
          this.hasErrorOccurred = false;
          this.footer = data['footer'].footerCollection.items;
        } else {
          this.hasErrorOccurred = true;
          this.footer = data['footer'].data;
        }
      },
      error: (error) => { }
    })
  }

  isMobile(): void {
    this.breakPointerObserver.observe("(max-width: 640px)")
      .subscribe((state: BreakpointState) => {
        this.isMobileScreen = state.matches;
      });
  }

  /* istanbul ignore next */
  getUserInfo(): void {
    this.dashboardService
      .fetchProfileCardInfo('network-only')
      .then((response) => {
        if (!response.id) {
          this.hasErrorOccurredForProfile = true;
        }
        this.profileCard = response;
      });
  }

  navigateFromSubLevel(url: string): void {
    this.drawer.close();
    this.router.navigateByUrl(url);
  }

  setProfile(profile: string): void {
    this.selectedProfile = profile;
  }

  ngOnDestroy(): void {
    this.drawer.openedChange.next(false);
    this.drawer.openedChange.unsubscribe();
  }

  /**
   * Opens LogoutDialog after screen size is considered
   * tablet screen size or horizontal screen. 
   */
  showLogoutDialog(): void {
    this.drawer.close();
    const logoutConfirmationDialogRef = this._userPortalDialogService.userPortalDialog(
      `Are you sure you want to leave?`,
      `   
      You are logging out of all LCPtracker applications.
      `,
      'Confirm',
      'Cancel'
    );

    logoutConfirmationDialogRef.afterClosed().subscribe(value => {
      if (value) {
        this.logout();
      } else {
        this.drawer.open();
      }
    })
  }
  /**
   * Opens BottomSheet when screen size is validated.
   */
  openConfirmationBottomSheet(): void {
    this.drawer.close();
    const logoutBottomSheetRef = this.bottomSheet.open(ConfirmationBottomSheetComponent, {
      data: {
        title: 'Are you sure you want to leave?',
        body: `<p>
                  You are logging out of all LCPtracker applications.
                  </p>`,
        confirmationButton: 'Confirm',
        cancelButton: 'Cancel'
      },
    });
    logoutBottomSheetRef.afterDismissed().subscribe(value => {
      if (value) {
        this.logout()
      } else {
        this.drawer.open();
      }
    });
  }

  /**
   * Evaluates if screen size is valid to display 
   * the confirmation bottom sheet
   */
  showLogoutConfirmation(): void {
    if (this.isMobileScreen) {
      this.openConfirmationBottomSheet()
    } else {
      this.showLogoutDialog();
    }
  }

  logout(): void {
    this.authenticationService.logout();
  }
}
