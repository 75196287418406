import { Component, Input } from '@angular/core';
import { TilesNode } from './app-list.interface';

@Component({
  selector: 'app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss']
})
export class AppCardComponent {
  @Input() product!: TilesNode;

  /**
   * Opens a new window to redirect users to selected product
   * @param url
   * @returns
   */
  onCardClick(url: string): void {
    document.location.href = url;
  }

  addProductImageBasedOnName(productName: string): string {
    switch (productName.trim()) {
      case 'LCPcertified':
        return './assets/images/icon_LCPcertified.svg'

      case 'LCPtracker Pro':
        return './assets/images/icon_LCPtrackerPro.svg'

      case 'LCPt-Pro Staff Admin':
        return './assets/images/icon_LCPtrackerPro.svg'

      case 'Workforce Manager':
        return './assets/images/icon_WorkforceManager.svg'

      case 'LCP Case Manager':
        return './assets/images/cm_icon.svg'

      case 'Active Insights':
        return './assets/images/icon_ActiveInsights.svg'

      case 'Case Manager':
        return './assets/images/icon_CaseManager.svg'

      default:
        return './assets/images/placeholder_product.png'
    }
  }

}
